.loader-container {
  display: inline;
  &:before {
    content: '';
    background-image: url("../../../shared/assets/img/tail-spin-dark.svg");
    background-size: cover;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: -4px;
    left: -9px;
    top: 5px;
    position: relative;
    box-sizing: border-box;
    margin-top: -16px;
  }
}
.form-primaryButton, .form-primaryButton-delete {
  .loader-container {
    display: inline;
    &:before {
      content: '';
      background-image: url("../../../shared/assets/img/tail-spin.svg");
      background-size: cover;
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-right: -20px;
      left: -19px;
      top: 5px;
      position: relative;
      box-sizing: border-box;
      margin-top: -16px;
    }
  }
}
/* Please see the article */

#editing, #highlighting {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 10px;
  padding: 10px;
  border: 0;
  width: calc(100% - 32px);
  height: 150px;
}
#editing, #highlighting, #highlighting * {
  /* Also add text styles to highlighing tokens */
  font-size: 15px;
  font-family: monospace;
  line-height: 1.5;
  tab-size: 2;
}


#editing, #highlighting {
  /* In the same place */
  position: absolute;
  top: 0;
  left: 0;
}


/* Move the textarea in front of the result */

#editing {
  z-index: 1;
}
#highlighting {
  z-index: 0;
}


/* Make textarea almost completely transparent */

#editing {
  color: transparent;
  background: transparent;
  caret-color: black; /* Or choose your favourite color */
}

/* Can be scrolled */
#editing, #highlighting {
  overflow-y: auto;
  white-space: pre-wrap; /* Allows textarea to scroll horizontally */
  outline: none;
}

/* No resize on textarea */
#editing {
  resize: none;
}


code[class*="language-"],
pre[class*="language-"] {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 15px;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: .4em .8em;
  margin: .5em 0;
  overflow: auto;
  /* background: url('data:image/svg+xml;charset=utf-8,<svg%20version%3D"1.1"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20width%3D"100"%20height%3D"100"%20fill%3D"rgba(0%2C0%2C0%2C.2)">%0D%0A<polygon%20points%3D"0%2C50%2050%2C0%200%2C0"%20%2F>%0D%0A<polygon%20points%3D"0%2C100%2050%2C100%20100%2C50%20100%2C0"%20%2F>%0D%0A<%2Fsvg>');
  background-size: 1em 1em; - WebCoder49*/
  background: black; /* - WebCoder49 */
}

.highlighted {
  background-color: #ffec68;
  border-radius: 3px;
  color: #9a4f3d;
}
.sending {

  &-cancel {
    cursor: pointer;
    position: relative;
    border-right: 1px solid rgba(0,0,0,.2);
    padding: 0 10px;
    padding-left: 0;
    margin-right: 10px;
    height: 18px;
    svg {
      width: 16px;
      opacity: .6;
      top: -3px;
      position: relative;
      &:hover {
        opacity: 1;

      }
    }
  }
  &-window {
    display: flex;
  }
  &-left {
    width: 50%;
    min-width: 630px;
    box-sizing: border-box;
    border-right: 1px solid rgba(0,0,0,.2);
    background: rgba(0,0,0,.02);
  }
  &-right {
    width: 50%;
    margin-left: 10px;
  }
}
.form-message {
  border-bottom: 1px solid rgba(0,0,0,.1);
  border-radius: 0px;
  padding: 0 0;
  display: flex;
  align-items: center;
  .form-label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .form-input {
    border: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    &:active {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &:disabled {
      background: none;
    }
  }
}

.form-message-textarea {
  min-height: 500px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  resize: vertical;
  border: none;
  padding: 10px 0;
  padding-top: 0;
  resize: none;
  &:focus {
    outline: none;
  }
}
.form-message-textarea-prompts {
  min-height: 500px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  resize: vertical;
  border: none;
  padding: 10px 0;
  padding-top: 0;
  resize: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #FFFFFF;
    color: rgba(0,0,0,.8);
  }
}
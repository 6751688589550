.fileUploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(0,0,0,.2);
  min-height: 200px;
  border-radius: 12px;

  &__legend {
    padding-bottom: 20px;
    font-size: 18px;
    color: rgba(0,0,0,.4)
  }
  &__fileName {
    display: inline-block;
    background: rgba(0, 87, 255, .3);
    margin-left: 8px;
    border-radius: 7px;
    padding: 2px 10px;
    color: rgb(0 87 255);
    font-size: 13px;
    margin-top: 8px;
    cursor: pointer;
    padding-right: 6px;
    &:hover {
      background: rgba(0, 87, 255, .4);
    }
  }
  &__deleteFile{
    font-size: 10px;
    bottom: 4px;
    position: relative;
    padding-left: 4px;
    opacity: .7;
    font-weight: bolder;
  }
}
/* App.css */

.cursor {
  animation: blink 1s infinite;
  &:after {
    display: inline-block;
    position: relative;
    content: '';
    width: 7px;
    height: 12px;
    background-color: black;
    opacity: .4;
    margin-left: 3px;
  }
}

@keyframes blink {
  0% {opacity: 1.0;}
  50% {opacity: 0.0;}
  100% {opacity: 1.0;}
}
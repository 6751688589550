.sales-behind-notes {
  padding: 10px;
  .notesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .notes-textarea {
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    line-height: 24px;
    padding: 10px;
    resize: vertical;

  }
  .notesControl {
    display: flex;
    margin-bottom: 0px;
  }
  .notes-button {
    height: 20px;
    background: rgb(51, 113, 195);
    border-radius: 7px;
    color: white;
    box-sizing: border-box;
    padding: 14px 30px;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid rgba(0,0,0,0);
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 0px;
    text-align: center;
    margin-top: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    &:hover {
        background: rgb(25, 102, 206);
      }
  }
  .notes-button-sub {
    height: 20px;
    background: rgb(51, 113, 195, 0);
    border-radius: 7px;
    color: rgba(0,0,0,.4);
    box-sizing: border-box;
    padding: 14px 30px;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.1);
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 0px;
    text-align: center;
    margin-top: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin-left: 0px;
    &:hover {
      color: rgba(0,0,0,.5)
    }
  }
}

.dashboardWidget {
  margin-left: 20px;
  &__header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
  }
  &__subheader {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
  &__card {
    width: 220px;
    height: 120px;
    background: white;
    margin-right: 20px;
    padding: 10px 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    }
    &__label {
      font-size: 12px;
      opacity: .7;
    }
    &__setupMailbox {
      &__name {
        font-size: 14px;
        font-weight: bold;
        margin-top: 4px;
      }
      &__pathArrow {
        display: inline-block;
        content: "";
        width: 12px;
        height: 12px;
        background-image: url('../../../shared/assets/img/icon-right-arrow.png');
        background-size: cover;
        margin-left: 0px;
        position: relative;
        top: 4px;

        margin-right: 1px;
        opacity: .7;
      }
      &__text {
        font-size: 14px;
        opacity: .85;
        padding-bottom: 3px;
        line-height: 22px;
        font-style: italic;
      }
    }
    &__campaigns {
      &__name {
        font-size: 14px;
        font-weight: bold;
        margin-top: 4px;
      }
      &__pathArrow {
        display: inline-block;
        content: "";
        width: 12px;
        height: 12px;
        background-image: url('../../../shared/assets/img/icon-right-arrow.png');
        background-size: cover;
        margin-left: 0px;
        position: relative;
        top: 4px;

        margin-right: 1px;
        opacity: .7;
      }
      &__stat {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        opacity: .7;
      }
    }
    &__myCompany {
      &__cardEdit {
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        background-image: url('../../../shared/assets/img/behind-editIcon.png');
        background-size: cover;
        margin-left: 0px;
        position: relative;
        top: 4px;

        margin-right: 1px;
        opacity: .7;
      }
      &__name {
        font-size: 16px;
        font-weight: bold;
        opacity: .85;
        margin-top: 10px;
      }
      &__description {
        font-size: 14px;
        opacity: .75;
        margin-top: 7px;
      }
    }
    &__header {
      font-size: 12px;
      font-weight: bold;
      opacity: .75;
    }
    &__container {
      display: flex;
      margin-bottom: 60px;
      margin-top: 30px;
    }
  }
  &__createOffer {
    width: 300px;
    height: 140px;
    padding: 20px;
    margin-right: 20px;
    border-radius: 7px;
    background: #1a71e4;
    background: linear-gradient(262.63deg, #0057FF 4.13%, #5A1ED9 39.06%, #3F65AE 97.27%);
    color: #a5ccff;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__header {
      font-size: 18px;
    }
    &__icon {
      width: 20px;
      height: 20px;
      background-size: cover;
      background-image: url("../../../shared/assets/img/megaphone-icon-w.png");
      opacity: .7;
      display: inline-block;
      margin-right: 10px;
    }
    &__description {
      font-size: 14px;
      font-weight: normal;
      padding-top: 5px;
    }
    &--green {
      background: #1a71e4;
      background: linear-gradient(262.63deg, #48b5b4 4.13%, #46beca 39.06%, #3fbdae 97.27%);
      color: #2e7170;
    }
    &__container {
      display: flex;
      margin-top: 30px;
    }
    &__button {
      color: #a5ccff;
      background: rgba(255,255,255,.3);
      border: none;
      box-shadow: none;
      padding: 20px 50px;
      &:hover {
        background: rgba(25,255,255,.1);
      }
    }
    &--green {
      color: #2e7170;
    }
  }
}
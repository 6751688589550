.MuiDataGrid-root {
  padding-top: 10px;
  .MuiDataGrid-withBorderColor {
    //border-color: rgba(0,0,0,0) !important;
  }
}
.MuiDataGrid-cellCheckbox, .MuiCheckbox-root {
  svg {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    width: 16px;
    height: 16px;
    color: rgba(0,0,0,0)
  }
}

.Mui-checked {
  svg {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    width: 16px;
    height: 16px;
    color: rgba(0,0,0,0);
    fill: #1a71e4;
    background-image: url("../../../../shared/assets/img/behind-checkbox-bg.svg");
    background-size: cover;
  }
}
.MuiDataGrid-root {
  .MuiDataGrid-row {
    .Mui-hovered {
      background-color: rgba(0,0,0,0);
    }
    &:focus {
      outline: none !important;
    }
    &:hover {
      background: none !important;
    }
  }
}
.hideGridHeader {
  .MuiDataGrid-columnHeaders {
    display: none !important;
  }
}
.grid {

  &__row {
    &__element {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 5px 0;
      &:hover {
        .grid__row__header {
          text-decoration: underline;
        }
      }
    }
    &__iconHeader {
      display: flex;
      align-items: center;
    }
    &__companyLogo {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    &__header {
      font-size: 14px;
      font-weight: 500;
      font-family: "Noto Sans", sans-serif;
      color: #082f7a;
    }
    &__category {
      font-size: 12px;
      font-family: "Noto Sans", sans-serif;
      color: rgba(0,0,0,.7);
    }
  }
}
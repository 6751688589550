.funnelEditor {
  &__container {
    display: flex;
  }
  &__left {
    width: 50%;
    padding-right: 20px;
  }
  &__right {
    width: 50%;
  }
}
.promptsPage {
  display: flex;
  height: 100vh;
  background: white;
  .split-sash-content.split-sash-content-vscode.split-sash-content-active {
    opacity: .7;
  }
  .react-split__sash.react-split__sash--vertical {
    width: 4px !important;
  }
  &__left {
    border-right: 1px solid rgba(0,0,0,.1);
    &__nav {
      display: flex;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      justify-content: space-between;
      padding-right: 10px;
    }
    &__promptPath {
      display: flex;
      padding-top: 6px;
    }
  }
  &__right {

  }
  .functions-settingButton {
    min-width: 0;
    padding-left: 6px;
    padding-right: 6px;
  }
  .functions-settingIcon {
    width: 16px;
    opacity: .8;
    top: 0px;
    position: relative;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &__dropdown-button {
    width: 20px;
    height: 20px;
    background-image: url("../../../shared/assets/img/icon-arrow-down.png");
    background-size: 9px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,.05);
    border-radius: 50%;
    top: 5px;
    left: 5px;
    opacity: .6;
    position: relative;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &__pathArrow {
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    background-image: url('../../../shared/assets/img/icon-right-arrow.png');
    background-size: cover;
    margin-left: -3px;
    position: relative;
    top: 1px;
    margin-right: 1px;
  }
  &__promptsForm {
    padding-top: 20px;
    padding-left: 14px;
    padding-right: 10px;
    line-height: 20px;
    height: calc(100vh - 77px);
    font-family: Roboto Mono,monospace;
    display: flex;
    flex-direction: column;
    &__bottomControl {
      display: flex;
      justify-content: space-between;
    }
    &__container {
      border-radius: 5px;
      border: 1px solid rgba(0,0,0,.1);
      padding: 10px;
      margin-bottom: 20px;
      min-height: 120px;
      textarea {
        width: 100%;
        min-height: 160px;
        border: none;
        font-size: 15px;
        resize: vertical;
      }
      label {
        font-size: 15px;
        font-family: "Noto Sans", sans-serif;
        opacity: .7;
        margin-bottom: 10px;
        display: inline-block;
      }
      div {
        height: 100%;
        &:focus {
          outline: none;

        }
      }
    }
  }
  &__promptsList {
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 10px;
    height: calc(100vh - 56px);
    overflow: auto;
    &__prompt {
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 10px;
      font-size: 14px;
      border-top: 1px solid rgba(0,0,0,.1);
      line-height: 20px;
      &:first-child {
        border-top: 1px solid rgba(0,0,0,0);
      }
      &:hover {
        opacity: .75;
      }
      &__title {
        font-weight: 700;
        font-size: 16px;
      }
      &__body {
        font-weight: 400;
        font-size: 14px;
        outline: none;
      }
      &__headline {
        font-style: italic;
        opacity: .75;
        font-size: 14px;
        outline: none;
        &:focus {
          outline: none;
        }
      }
      &__create {
        padding: 20px;
        background: rgba(0,0,0,.05);
        border-radius: 7px;
        display: inline-block;
        color: rgba(0,0,0,.85);
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        &:hover {
          background: rgba(0,0,0,.1);
        }
      }
    }
  }
}
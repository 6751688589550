.sipWidget {
  &__box {
    position: absolute;
    z-index: 999;
    height: 40px;
    display: flex;
    width: 340px;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    backdrop-filter: blur(4px);
    border-radius: 16px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    animation: fadeIn ease-in .5s backwards;
    &--extended {
      width: 600px;
      max-width: 100%;
      height: 400px;
      max-height: 100vh;
      padding-top: 10px;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }
  &__button {
    min-width: 50px;
    border-radius: 32px;
    background: red;
    color: white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 40px;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
    &__grey {
      background: rgba(0,0,0,.1);
      color: rgba(0,0,0,.8);
    }
  }

  &__timer {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: rgba(0,0,0,.8);
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
  }
  &__hangUp {
    //background-color: rgba(0,0,0,.1);
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-image: url("../../shared/assets/img/call-end-red.png");
    background-size: 26px;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
  &__extend {
    background-color: rgba(0,0,0,.05);
    background-image: url('../../shared/assets/img/icon-arrow-down.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 9px;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 5px;
    opacity: .6;
    position: relative;
    top: 5px;
    width: 20px;
    margin-right: 10px;
  }
  &__transcript {
    &__container {
      display: flex;
      flex-direction: column;
      background-color: #f6f5f0;
      border-radius: 3px;
      color: #353535;
      box-sizing: border-box;
      padding: 12px 20px;
      font-family: monospace;
      font-size: 13px;
      line-height: 24px;
      margin: 10px;
      height: 340px;
      max-height: 340px;
      overflow-y: auto;
      flex-shrink: 0;
    }
  }
}
.moustacheDropdownElement {
  font-size: 13px;
  li {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &-container {
    display: flex;
  }
  &-name {
    min-width: 200px;
    flex-shrink: 0;
  }
  &-item {
    min-width: 200px;
    flex-shrink: 0;
  }
  &-ul {
    width: 240px;
    ul {
      width: 240px;
    }
  }
}
.notesNote {
  display: flex;
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  padding-left: 0;
  &:hover {
    background: rgba(0,0,0,.03);
  }
  &Date {
    font-size: 12px;
    opacity: .6;
    padding-top: 2px;
  }
}
.notesNote-selected {
  display: inline-flex;
  background: rgba(0,0,0,.2);
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}
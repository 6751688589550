.screenLoader {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-content: center;
  justify-content: center;
  background-image: url("../../../shared/assets/img/tail-spin-dark.svg");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .3;
}
.theme-dark {
  .screenLoader {
    background-image: url("../../../shared/assets/img/tail-spin.svg");
  }

}
.campaignPage-grid-container {
  display: flex;
}
.campaignPage-grid-campaigns {
  min-width: 520px;
  max-width: 520px;
}
.campaignPage-grid-tasks {
  min-width: 600px;
  max-width: 800px;
  border-left: 1px solid rgba(0,0,0,.1);
}
.campaignPage-name {
  font-weight: bold;
  font-size: 16px;
}
.campaignPage-dateStatus {
  display: flex;
}
.campaignPage-status-success {
  margin-left: 5px;
  font-size: 11px;
  display: inline-block;
  background: #DDF5C7;
  color: #428124;
  padding: 2px 10px;
  border-radius: 12px;
}
.campaignPage-status-init {
  margin-left: 5px;
  font-size: 11px;
  display: inline-block;
  background: #D6F4F5;
  color: #1E57B5;
  padding: 2px 10px;
  border-radius: 12px;
}
.campaignPage-status-waiting {
  margin-left: 5px;
  font-size: 11px;
  display: inline-block;
  background: #e3ebeb;
  color: #555555;
  padding: 2px 10px;
  border-radius: 12px;
}
.campaignPage-status-fail {
  margin-left: 5px;
  font-size: 11px;
  display: inline-block;
  background: #FBE8F1;
  color: #A4243F;
  padding: 2px 10px;
  border-radius: 12px;
}
.data-emailStatus {
  display: flex;
  align-items: center;
}
.data-opened {
  img {
    margin-left: 5px;
    position: relative;
    top:0px;
    margin-right: 2px;
  }
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,.6);
  line-height: 0px;
  display: flex;
  align-items: center;
}
.logPage {
  &__importData {
    &__container {
      display: flex;
      flex-direction: row;
      padding: 7px 14px;
      background: rgba(0, 87, 255, 0.18);
      border: #0057FF;
      color: #364e81;
      align-items: center;
      border-radius: 7px;
      margin-bottom: 12px;
    }
    &__text {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  &__container {
    display: flex;
    background: white;
    max-height: 100vh;
    height: 100vh;
  }
  &__left {
    width: 100%;
  }
  &__right {
    width: 100%;
    border-left: 1px solid rgba(0,0,0,.1);
    padding: 20px;
    padding-top: 0px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
  &__chat {
    &__transcript {
      background-color: #f6f5f0;
      border-radius: 3px;
      color: #353535;
      box-sizing: border-box;
      padding: 12px 20px;
      font-family: monospace;
      font-size: 13px;
      line-height: 24px;
    }
  }
}
.MuiDataGrid-cellCheckbox, .MuiCheckbox-root {
  svg {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    width: 16px;
    height: 16px;
    color: rgba(0,0,0,0)
  }
}

.Mui-checked {
  svg {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    width: 16px;
    height: 16px;
    color: rgba(0,0,0,0);
    fill: #1a71e4;
    background-image: url("../../../shared/assets/img/behind-checkbox-bg.svg");
    background-size: cover;
  }
}
.MuiDataGrid-columnHeaders {
  display: none !important;
}
.contactGrid {
  &__stringAvatar {
    margin-right: 10px;
      div {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bolder;
      }
  }
}
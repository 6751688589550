.login-form {
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  &-text {
    font-size: 20px;
    span {
      font-size: 80%;
      display: inline-block;
      padding-top: 10px;
    }
  }
  &-control {
    max-width: 300px;
    padding-top: 30px;
    div {
      margin-bottom: 14px;
    }
    .form-button {
      padding: 16px 54px;
      font-size: 14px;
    }
    .form-primaryButton {
      padding: 16px 54px;
      font-size: 14px;
    }
  }
}
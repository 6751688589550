.profileCenter {
  text-align: center;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profileString {
  display: flex;
  margin-top: 14px;
  font-size: 18px;
  label {
    opacity: .7;
  }
  .profileStringValue {
    font-weight: 700;
  }
}
.profileUpgrade {

  a {
    background-color: #7000FF;
    display: inline-block;
    color:#FFFFFF;
    text-decoration: none;
    padding: 5px 10px;
    border-radius:12px;
    position: relative;
    top: -5px;
    color: #FFFFFF;
    text-decoration: none;
  }
}
.profileLogout {
  font-size: 14px;
  opacity: .8;
  .loginRounded {
    font-size: 14px;
    position: relative;
    top:2px;
    left: -7px;
  }
}
.profileManage {
  font-size: 14px;
  opacity: .8;
  margin-right: 20px;
}
.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.data-emailStatus {
  display: flex;
  align-items: center;
}
.data-opened {
  img {
    margin-left: 5px;
    position: relative;
    top:0px;
    margin-right: 2px;
  }
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,.6);
  line-height: 0px;
  display: flex;
  align-items: center;
}
.func-textarea {
  outline: none;
}
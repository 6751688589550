@import "../../entitites/sales/companyCard/companyCard";
.functions-editIcon {
  width: 14px;
  opacity: .8;
  top: 0px;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
@import "../../../app/styles/vars";

.settingsCards {
  padding-top: 40px;
  display: flex;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
  a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    padding: 20px 60px;
    border-radius: 7px;
    border: 1px solid rgba(0,0,0,.01);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    font-family: 'Noto Sans', sans-serif;
    color: black;
    margin-right: 20px;
    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 20px;
      text-align: center;
    }
    .theme-dark & {
      color: white;
      border: 1px solid rgba(255,255,255,.1);
    }
    &:hover {
      opacity: .8;
    }
  }
}
@import "../../app/styles/vars";
.navbar {
    width: 100%;
    background-color: rgb(153 182 229 / 10%);
    height: 40px;
    border-bottom: 1px solid rgb(0 0 0 / 3%);
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    align-items: center;
    display: flex;
    &-extended {

        padding-right: 10px;
        background: none;
        display: flex;
        animation: navsearch 300ms;

    }
    @keyframes navsearch {
        from { opacity: 1; width: 280px;}
        to { opacity: 1; width: 100%;}
    }
    @media (max-width: $breakpoint-tablet) {
        height: 50px;
    }
    .dashboardContainer {
        display: flex;
        justify-content: space-between;
    }
    .navbarLogo {
        img {
            opacity: 1;

            height: 30px;
            position: relative;
            top: 2px;
        }
        margin-right: 10px;
    }
    .navbarLink {
        margin-left: 0px;
        display: flex;
        margin-right: 0;
        font-weight: 500;
        height: 20px;
        line-height: 0;
        align-items: center;
        padding: 2px 14px;
        font-size: 13px;
    }
    .navbarLink-selected {
        background: #1a71e4;
        background: rgb(50 114 195);
        background: rgba(0,87,255,1);
        border-radius: 32px;
        padding-top: 1px;
        color: #FFFFFF;
        &:hover {
            color: #FFFFFF;
        }
    }
    .navbar-left {
        display: flex;
        align-items: center;
        .howTo {
            font-family: 'Noto Sans', sans-serif;
            font-size: 14px;
            font-weight: 700;
            margin-left: 20px;
            padding-top: 3px;
            cursor: pointer;
            color: rgba(0,0,0,.8);
            &:hover {
                color: rgba(0,0,0,1);
            }
        }
    }
    .navbar-right {
        display: flex;
        align-items: center;

    }
    .navbar-desktop {
        display: flex;
        @media (max-width: $breakpoint-tablet) {
            display: none;
        }
    }
    .navbar-mobile {
        display: none;
        @media (max-width: $breakpoint-tablet) {
            display: block;
        }
    }
    .navbar-menu-icon {
        width: 40px;
        height: 40px;
        background-image: url("../../shared/assets/img/menu-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px;
        cursor: pointer;
        opacity: .7;
        position: relative;
        left: 11px;
        &:hover {
            opacity: 1;
        }
    }
    .navbarLogout {
        a {
            font-family: 'Noto Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-decoration: none;

            color: rgba(0, 0, 0, 0.9);



        }
        span {
            font-weight: 400;
            color: rgba(0,0,0,.5);

        }
        &:hover {
            a,span {
                color: rgba(0,0,0,1);
            }

        }
        .userName {
            display: inline;
            font-weight: 700;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
            @media (max-width: $breakpoint-tablet) {
                display: none;
            }
        }

    }
    .loginRounded {
        font-size: 18px !important;
        top: 4px;
        position: relative;
        right: 2px;
    }


}
.howTo-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    div {
        &:focus {
            outline: none;
        }
    }

}
.howTo-box {
    width: 630px;
    max-width: 90%;
    border-radius: 5px !important;
    background-color: #fff !important;
    color: #000 !important;
    margin-top: 50px;
    @media (max-width: $breakpoint-tablet) {
        margin-top: 0px;
    }
    .modalHeader {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 26px;
        color: rgb(0,0,0,);
        padding-bottom: 24px;
        text-align: left;
        padding-top: 23px;
    }

    .modalControl {
        /* display: flex; */
        /* justify-content: space-between; */
        padding-top: 50px;
        display: inline-block;
        text-align: center;
        width: 100%;
        .modalSubmit {
            background: rgb(51, 113, 195);
            color: white;
            margin-left: auto;
            margin-right: auto;
            display: inline-block;
        }
    }


}
.howTo-row {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: row;
    background: rgba(0,0,0,.05);
    border-radius: 5px;
    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
    }
}
.howTo-text {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    /* margin-left: 34px; */
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    @media (max-width: $breakpoint-tablet) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    span {
        font-style: italic;
        font-size: 0.9em;
        color: rgba(0,0,0,.7);
    }
    b {
        font-size: 1.1em;
    }
}
.howTo-image {
    width: 130px;
    flex-shrink: 0;
    height: 120px;
    background: rgb(67 76 144);
    align-items: center;
    align-content: center;
    display: flex;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0px 20px;
    @media (max-width: $breakpoint-tablet) {
        margin-top: 20px;
        height: 100px;
    }
    img {
        width: 100%;
    }
}
.howTo-hear {
    img {
        width: 100px;
        margin-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }
}
.navbarLink {
    display: block;
    margin-right: 20px;
    text-decoration: none;
    color: rgba(0,0,0,.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 0;
    &:hover {
        color: rgba(0,0,0,1);
    }
}
.MuiMenu-list {
    margin-right: 0;
    width: 100% !important;
}
.filter-website-select {

    .MuiInputBase-root {
        padding: 0;
        padding-right: 0px;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        border: none;
        #filter-website-select {
           padding-left: 0;
        }

        fieldset {
            border: none;
        }
    }
    li {
        width: 100%;
    }
}
.filter-cat-select {
}
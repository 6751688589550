@import "../../../app/styles/vars";

.page {
  &-grid {
    display: flex;
    flex-direction: row;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }
  &-main {
    width: 55%;
    min-width: 600px;
    flex-shrink: 0;
    @media (max-width: $breakpoint-tablet) {
      min-width: 100%;
    }
  }
  &-left {
    width: 25%;
    min-width: 200px;
  }
}
.sector-name {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  margin-top: 12px;
}

@import "./vars.scss";
.m-move-leftPart {
  @media (max-width: $breakpoint-tablet) {
    transform: translateX(-20vw);
    animation: m-move-animation-left 500ms;
    background: #FFFFFF;
    z-index: 1;
    max-width: 100vw;
    overflow-x: hidden;
  }
}
.m-move-rightPart {
  @media (max-width: $breakpoint-tablet) {
    transform: translateX(-100vw);
    animation: m-move-animation-right 500ms;
    background: #FFFFFF;
    z-index: 2;
    margin-left: 0;
    max-width: 100vw;
    overflow-x: hidden;
  }
}
@keyframes m-move-animation-left {
  from {transform: translateX(0)}
  to {transform: translateX(-20vw)}
}
@keyframes m-move-animation-right {
  from {transform: translateX(0)}
  to {transform: translateX(-100vw)}
}
.m-move-leftPart-back {
  @media (max-width: $breakpoint-tablet) {
    transform: translateX(0vw);
    animation: m-move-animation-left-back 500ms;
    background: #FFFFFF;
    z-index: 1;
  }
}
.m-move-rightPart-back {
  @media (max-width: $breakpoint-tablet) {
    transform: translateX(100vw);
    animation: m-move-animation-right-back 500ms;
    background: #FFFFFF;
    z-index: 2;
    margin-left: 0;
  }
}
@keyframes m-move-animation-left-back {
  from {transform: translateX(-20vw)}
  to {transform: translateX(0)}
}
@keyframes m-move-animation-right-back {
  from {transform: translateX(-100vw)}
  to {transform: translateX(0)}
}


.ku {
  &__search {
    &__input {
      display: flex;
      flex-direction: row;


      width: 100%;
      padding-top: 5px;
      input {
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 7px;
        outline: none;
        width: 100%;
        padding: 4px;
        margin-right: 10px;
      }
    }
  }
  &__grid {
    display: flex;
    background: #FFFFFF;
    height: 100vh;
    overflow-y: auto;
    &__col {
      display: flex;
      flex-direction: column;

    }
    &__row {
      display: block;
    }
    &__element {
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      &--selected {
        background: #efefef;

      }
    }
    &__search {
      &--row {
        border-bottom: 1px solid rgba(0,0,0,.05);
      }
      &--element {
        font-size: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
        .companyCard {
          padding-left: 0;
        }
        &:hover {
          .ku__grid__search {
            &__header {
              text-decoration: underline;
            }
            &__category {

              color: rgba(0,0,0,.9);
            }

          }
        }
      }
      &__header {
        font-size: 14px;
        font-weight: 500;
        font-family: "Noto Sans", sans-serif;
        color: #082f7a;
      }
      &__category {
        font-size: 12px;
        font-family: "Noto Sans", sans-serif;
        color: rgba(0,0,0,.7);
      }
    }
  }
}

.tutorial {
  &Container {
    padding: 40px 20px;
    background: #434c90;
    color: white;
  }
  &control {
    padding-top: 30px;
    display: flex;
    align-items: center;
  }
}
@import '../../../app/styles/vars.scss';
.nav-search {
  &-searchForm {
    width: 100%;
    box-sizing: border-box;
    //background: rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    height: 34px;
    display: flex;
    margin-top: 12px;
    margin-left: -10px;
    padding-right: 0px;

    @media (max-width: $breakpoint-tablet) {
      height: 70px;
      background: rgba(0, 0, 0, 0);
    }
    .search-groups {
      background: rgba(0, 0, 0, 0.07);
      margin-right: 0px;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    .form {
      &-select {
        font-size: 13px;
        margin-top: 1px;
        padding: 0px 7px;
        padding-right: 0px;
        border-radius: 0;
        border: none;
        box-shadow: none;
        font-family: "Noto Sans", sans-serif;
        padding-top: 3px;
        fieldset {
          border: none !important;
          padding-right: 0;
        }
        svg {
          fill: black;
        }
        .MuiSelect-select {
          color: black;
          font-size: 13px;
          padding: 4px 3px;
          padding-top: 2px;
          font-family: "Noto Sans", sans-serif;
          border-radius: 7px !important;
          border: none !important;

          div {
            border: none;

          }

        }
      }
      @keyframes slideaway {
        from { opacity: 0; width: 0px;}
        to { opacity: 1; width: 130px;}
      }
      &-actions {
        background: #0057ff;
        color: #FFFFFF;
        border-radius: 6px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        animation: slideaway 300ms;
        visibility: visible;
        padding-top: 1px;
        color: #FFFFFF;
        .MuiSelect-select {
          color: white;
          font-size: 13px;
          padding: 4px 3px;
          padding-top: 2px;
          font-family: "Noto Sans", sans-serif;
          border-radius: 7px !important;
          border: none !important;

          div {
            border: none;

          }

        }
        .actions-close {
          margin-top: 1px;
        }
        i {
          color: #FFFFFF;
        }
        svg {
          fill: #FFFFFF;
          margin-top: 0;
        }
      }
    }
    .filterTagsContainer {
      display: flex;
      padding-top: 3px;
      width: 100%;
      .filterTag {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 3px;
        background:  rgba(0,87,255,.2);
        color: rgba(0,87,255,1);
        border-radius: 7px;
      }
    }
    .search-container {
      width: 100%;
      height: 34px;
      min-width: 200px;
      border-radius: 6px;
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      padding-left: 5px;
      padding-right: 0;
      justify-content: space-between;
      background: none;
      background: rgba(0, 0, 0, 0.07);
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: 1px solid rgba(0,0,0,.1);
      @media (max-width: $breakpoint-tablet) {
        align-items: center;

      }
    }
    .search-input {
      width: 80%;
      height: 26px;
      min-width: 200px;
      border-radius: 6px;
      border: 1px solid rgba(0,0,0,0);
      box-sizing: border-box;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0);
      padding-left: 0px;
      background: none;
      padding-top: 0px;
      @media (max-width: $breakpoint-tablet) {
        background: rgba(0,0,0,.1);
        width: 100%;
        height: 34px;
        padding-top: 0;
        margin-top: 5px;
        padding-left: 5px;
      }
      &:focus {
        outline: none;
      }
    }
    &-string {
      display: flex;
      flex-direction: row;
    }
    form {
      display: flex;
      flex-direction: column;
      input {
        height: 20px;
        border-radius: 6px;
        border: 1px solid rgba(0,0,0,.2);
      }
      .search-button {
        margin-left: 1%;
        color: white;
        box-sizing: border-box;
        cursor: pointer;
        box-shadow: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 33px;
        background: rgba(0,87,255,1);
        border-radius: 7px;
        color: white;
        box-sizing: border-box;
        padding: 13px 7px;
        cursor: pointer;
        box-shadow: none;
        border: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0,0,0,.1);
        //box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
        margin-right: 0;
        background-image: url("../../../shared/assets/img/findIcon.png");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 5px center;
        padding-left: 26px;
        padding-right: 7px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:hover {
          opacity: 0.8;
        }
      }
      .filter-button {
        margin-left: 1%;
        border-radius: 7px;
        color: rgba(0,0,0,.8);
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        box-shadow: none;
        border: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 33px;
        border-radius: 7px;
        color: rgba(0,0,0,.8);
        box-sizing: border-box;
        padding: 13px 17px;
        cursor: pointer;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,0);
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        //box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
        background-image: url("../../../shared/assets/img/filterIcon.png");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 0 center;
        padding-left: 20px;

        padding: 13px 17px;
        margin-top: 0px;
        padding-right: 2px;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
@import "../../../app/styles/vars";
.pane-container {
  display: flex;
  height: 100vh;
}
.pane-left, .pane-right {
  height: 100%;
  overflow: auto;
  background: #f0f0f0;
  flex-grow: 1;
}
.resizer {
  cursor: ew-resize;
  background-color: #333;
  width: 2px;
  height: 100%;
  z-index: 1;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh);
  overflow-y: hidden;


  &-nav {
    &-right {
      padding-top: 12px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    width: 200vw;
    min-width: 200vw;
    max-width: 200vw;
    overflow-x: hidden;
  }
  &-left {
    width: 50%;
    min-width: 600px;
    margin-right: 0.5%;
    @media (max-width: $breakpoint-tablet) {
      width: 100vw;
      min-width: 100vw;
      max-width: 100vw;
      margin-right: 0;
    }
  }
  &-right {
    width: 49%;
    padding-left: 0.5%;
    padding-right: 10px;
    min-width: 600px;
    overflow-y: auto;
    border-left: 1px solid rgba(0,0,0,.1);
    //background-color: #F4F0EB;
    @media (max-width: $breakpoint-tablet) {
      width: 100vw;
      min-width: 100vw;
      max-width: 100vw;
    }
  }
}
.behind-dataGrid {
  //height: calc(100vh - 144px);
  //display: flex;
  overflow-x: auto;
}
.footer {
  margin-top: 0 !important;
}
.companyCard {
  margin-top: 10px;
  //background-color: rgba(0,0,0,.02);
  background: white;
  padding: 0 10px;
  margin-bottom: 20px;
  border-radius: 12px;
  //box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 20%);
  font-size: 14px;
  max-width: 100%;
  margin-right: 5px;
  overflow-x: hidden;
  word-break: break-word;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 60px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-description {
    font-size: 15px;
    line-height: 26px;
    color: rgba(0,0,0,.85);
  }
  a {
    font-size: 13px;
    color: rgba(0,0,0,.6);
    line-height: 0;
    &:hover {
      color: rgba(0,0,0,.8);
    }
  }
  &-name {
    font-size: 20px;
    font-weight: bolder;
  }
  &-category {
    padding-top: 5px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 12px;
  }
  &-line {
    padding-top: 12px;
    padding-bottom: 12px;
    &-container {
      display: flex;
      padding-top: 12px;
      padding-bottom: 12px;
      justify-content: space-between;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
    }
    &-col {
      width: 45%;
    }
  }
  &-border {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  &-parsingBorder {
    border-top: 1px solid rgba(0,0,0,.1);
    height: 14px;
    box-sizing: border-box;
    margin-top: 14px;
  }
  &-parsingProgress {
    height: 28px;
    padding-top: 14px;
    box-sizing: border-box;
    .css-qhoknl-MuiLinearProgress-bar1 {
      background-color: rgb(0, 87, 255);
    }
    .css-fl5ss-MuiLinearProgress-root {
      height: 2px !important;
    }
    .css-8ub8io-MuiLinearProgress-dashed {
      background-image: radial-gradient(rgb(0, 87, 255) 0%, rgb(0, 87, 255) 0%, transparent 0%);
      height: 2px !important;
    }
    .css-1qdnzt4-MuiLinearProgress-bar2 {
      background-color: rgb(134 172 255);
    }
    //rgb(0, 87, 255);
  }
  &-parsingProgressLabel {
    color: rgb(0, 87, 255);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  &--parsingProgressLabel-legend {
    color: rgb(0, 87, 255);
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
  }
  &-phone {
    opacity: .8;

  }
  &-links {
    padding-top: 12px;
  }
  &-icon {
    width: 14px;
    opacity: .6;
    padding-right: 7px;
  }
}
.mainTable {
  &-filter {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 10px;
    padding: 12px;
    &-header {
      opacity: .8;
      padding-top: 5px;
      font-size: 12px;
    }
    &-element {
      margin-left: 16px;
      display: flex;
      align-items: center;
      height: 40px;
      &:first-child {
        margin-left: 0;
      }
      label{
        font-size: 14px;
        margin-right: 2px;
      }
      input[type=text] {
        height: 20px;

      }
      input[type=number] {
        margin-left: 7px;
        padding: 7.5px 4px 7.5px 5px;
        box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
        background: #FFFFFF;
        height: 10px;
        outline: none;
        border: none;
      }
    }
    &-string {
      display: flex;
      flex-direction: row;
      padding-top: 0px;
      width: 100%;
    }
  }

  &-searchForm {
    padding-top: 10px;
    width: 100%;
    .filterTagsContainer {
      display: flex;
      padding-top: 3px;
      width: 100%;
      .filterTag {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 3px;
        background:  rgba(0,87,255,.2);
        color: rgba(0,87,255,1);
        border-radius: 7px;
      }
    }
    .search-container {
      width: 100%;
      height: 28px;
      min-width: 200px;
      border-radius: 6px;
      border: 1px solid rgba(0,0,0,.1);
      box-sizing: border-box;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
      padding-left: 5px;
      padding-right: 0;
      justify-content: space-between;
    }
    .search-input {
      width: 80%;
      height: 26px;
      min-width: 200px;
      border-radius: 6px;
      border: 1px solid rgba(0,0,0,0);
      box-sizing: border-box;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0);
      padding-left: 0px;
      &:focus {
        outline: none;
      }
    }
    &-string {
      display: flex;
      flex-direction: row;
    }
    form {
      display: flex;
      flex-direction: column;
      input {
        height: 20px;
        border-radius: 6px;
        border: 1px solid rgba(0,0,0,.2);
      }
      .search-button {
        margin-left: 1%;


        height: 20px;
        color: white;
        box-sizing: border-box;
        cursor: pointer;
        box-shadow: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 20px;
        background: rgba(0,87,255,1);
        border-radius: 7px;
        color: white;
        box-sizing: border-box;
        padding: 13px 7px;
        cursor: pointer;
        box-shadow: none;
        border: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0,0,0,.1);
        //box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: 0;
        background-image: url("../../../shared/assets/img/findIcon.png");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 5px center;
        padding-left: 26px;
        padding-right: 7px;
        &:hover {
          opacity: 0.8;
        }
      }
      .filter-button {
        margin-left: 1%;
        height: 20px;
        border-radius: 7px;
        color: rgba(0,0,0,.8);
        box-sizing: border-box;
        padding: 13px 17px;
        text-align: center;
        cursor: pointer;
        box-shadow: none;
        border: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 20px;
        border-radius: 7px;
        color: rgba(0,0,0,.8);
        box-sizing: border-box;
        padding: 13px 17px;
        cursor: pointer;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,0);
        font-family: 'Noto Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        margin-top: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        //box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
        background-image: url("../../../shared/assets/img/filterIcon.png");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 0 center;
        padding-left: 20px;
        padding-right: 2px;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
.MuiDataGrid-root .dataGridImage {
  height: 24px;
  width: 24px;
}
.MuiAutocomplete-root {
  .MuiButtonBase-root {
    button, .MuiButtonBase-root {
      display: none !important;
    }
  }
  .MuiInputBase-root {
    border: none !important;
  }
  input {
    height: 9px !important;
  }
  .MuiInputBase-root {
    border: none !important;
    padding-top: 7px !important;
  }
  .MuiFormControl-root {
    border: none !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiAutocomplete-input {
    padding: 7.5px 4px 7.5px 5px;
    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
    background: #FFFFFF;
    height: 30px;
  }
}
.startMessage {
  font-size: 13px;
  font-style: italic;
  opacity: .4;
  padding-bottom: 30px;
}
.selectMessage {
  height: 90vh;
  display: flex;
  align-items: center;
  font-style: italic;
  opacity: .8;
  font-size: 22px;
}
.groupsNav {
  padding-top: 12px;
  .MuiTabs-indicator {
    display: none !important;
    height: 20px;

  }
  .Mui-selected {
    border: 1.4px solid #006EFF;
    padding-right: 26px;
    &:first-child {
      padding-right: 5px;
      &:before {
        content: '';
        background: none;
        display: inline-block;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 2px;
        top: 4px;
      }
    }
    &:before {
      content: '';
      background-image: url("../../../shared/assets/img/edit-icon.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 2px;
      top: 4px;
    }
  }
  button {
    text-transform: none;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 7px;
    padding: 3px 4px;
    height: 20px;
    min-height: 30px;
    max-height: 30px;
    margin-right: 4px;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiTabs-root {
    //margin-left: -10px;
    min-height: 30px;
    height: 30px;
    max-height: 30px;
  }
  .groupCreateNew {
    border: 1.4px dashed rgba(0,0,0,.3);
    color: rgba(0,0,0,.4);
    padding-left: 18px;
    padding-right: 4px;
    &:before {
      content: '+';
      display: inline-block;
      background: none;
      height: 20px;
      width: 20px;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}
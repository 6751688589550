.employeeData-accepted {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-image: url("../../shared/assets/img/behind-icon-tick.svg");
    background-size: cover;
    background-color: #508340;
    margin-right: 5px;
    position: relative;
    top: 1px;
}
.employeeData-TOaccept {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-image: url("../../shared/assets/img/behind-icon-warning.svg");
    background-size: cover;
    background-color: #CA9E00;
    margin-right: 5px;
    position: relative;
    top: 1px;
}
.group-wrapper {
  width: 100%;
  overflow-x: auto;
}
.groupNav {
  display: flex;
  max-width: 1000%;
  &-nav {
      display: flex;
      flex-shrink: 0;
      margin-right: 4px;
    .groupNav-name {
      margin-right: 7px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      border: 1px solid rgba(0,0,0,.3);
      border-radius:8px;
      padding: 3px 10px;
      flex-shrink: 0;
      &:hover {
        color: #0057ff;
        border: 1px solid #0057ff;
      }
    }
    .groupNav-edit {
      display: none;
    }
  }
  &-selected {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 4px;
    .groupNav-name {
      margin-right: 7px;
      cursor: default;
      font-size: 14px;
      display: flex;
      color: #0057ff;
      border: 1px solid #0057ff;
      border-radius:8px;
      padding: 3px 10px;
      flex-shrink: 0;
      display: flex;
      &:hover {
        color: #0057ff;
        border: 1px solid #0057ff;
      }
    }
    .groupNav-edit {
      font-size: 12px;
      display: flex;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #0057ff;
      }
    }
  }
}
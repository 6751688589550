.companyCard {
  //margin-top: 10px;
  padding: 26px;
  //background-color: rgba(0,0,0,.02);
  background: white;
  margin-bottom: 20px;
  border-radius: 12px;
  //box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 20%);
  font-size: 14px;
  padding-top: 0;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-description {
    font-size: 15px;
    line-height: 26px;
    color: rgba(0,0,0,.85);
    padding-top: 0px;
  }
  a {
    font-size: 13px;
    color: rgba(0,0,0,.6);
    line-height: 0;
    &:hover {
      color: rgba(0,0,0,.8);
    }
  }
  &-name {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: bolder;
      padding-bottom: 0;
      display: inline-block;
    }
  }
  &__stringAvatar {
    div {
      width: 40px;
      height: 40px;
      margin-right: 14px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
    }

  }
  &-category {
    padding-top: 5px;
    //border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 12px;
  }
  &-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    //border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 10px;
    margin-bottom: 5px;
    button {
      margin-right: 10px;
    }
  }
  &-line {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &-border {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  &-phone {
    &__element {
      cursor: pointer;
    }
  }
  &-links {
    padding-top: 12px;
  }
  &-icon {
    width: 14px;
    opacity: .6;
    padding-right: 7px;
  }
}
.personCardLogs {
  padding-bottom: 20px;
  padding-top: 10px;
  &--unavailable {
    font-style: italic;
    opacity: .4;
  }
  &__line {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -5px;
    padding-left: 5px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      background: rgba(0, 87, 255, .05);
      border-radius: 4px;
    }
  }
  .directionImage {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
